import { store } from '../../index';
import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import { fetchDdbQuery } from '@/api-rosie/utils';
import { Nullable } from '@/types';
import axios from 'axios';
// @ts-ignore
import awsConfig from '../../../environments/awsconfig.js';
import { BreachProbabilityModule, BreachProbabilityRisk } from './breach-probability';
const { severityApiUrl } = awsConfig;

const RANGECOND_LOSS_SEVERITY = 'LossSeverity#current';

export enum LossSeverityRiskGroup {
  'Low' = 'Low',
  'Medium' = 'Medium',
  'Medium-High' = 'Medium-High',
  'High' = 'High',
  'Highest' = 'Highest',
}

const riskBreachMap: Record<BreachProbabilityRisk, LossSeverityRiskGroup | null> = {
  [BreachProbabilityRisk.LowRiskProb]: LossSeverityRiskGroup['Low'],
  [BreachProbabilityRisk.LowMediumRiskProb]: null,
  [BreachProbabilityRisk.MediumRiskProb]: LossSeverityRiskGroup['Medium'],
  [BreachProbabilityRisk.MediumMediumHighRiskProb]: null,
  [BreachProbabilityRisk.MediumHighRiskProb]: LossSeverityRiskGroup['Medium-High'],
  [BreachProbabilityRisk.MediumHighHighRiskProb]: null,
  [BreachProbabilityRisk.HighRiskProb]: LossSeverityRiskGroup['High'],
  [BreachProbabilityRisk.HighHighestRiskProb]: null,
  [BreachProbabilityRisk.HighestRiskProb]: LossSeverityRiskGroup['Highest'],
};

type LossCurrency = 'USD' | 'EUR';

type LossLegend = 'percent';

interface LossSeverityQueryResponse {
  RiskGroup?: LossSeverityRiskGroup;
  DataType?: string;
  LossSeverityPlot?: string;
  DataPoints?: string;
  LossSeverityAxisXCurrency?: LossCurrency;
  FrequencyAxisYLegend?: LossLegend;
  CurrentRisk?: 'TRUE' | 'FALSE';
  InsurableLossCoefficient?: string;
}

export enum LossSeverityUnit {
  bln = 'bln',
  mln = 'mln',
  k = 'k',
  none = 'none',
}

export type LossSeverityPlotItem = {
  LossSeverityAxisXCurrency?: LossCurrency;
  LossSeverityAxisXUnit: LossSeverityUnit;
  LossSeverityAxisX: number;
  LossSeverityAxisXRaw: number;
  FrequencyAxisY: number;
  FrequencyAxisYLegend?: LossLegend;
  NetPresentValue?: number;
  NetPresentValueLegend?: LossSeverityUnit;
};

export enum LossTypes {
  insurable = 'insurable',
  economic = 'economic',
}

type LossSeverityPlotsData = Partial<Record<LossSeverityRiskGroup, LossSeverityPlotItem[]>>;

export type WeightedNPVItem = {
  WeightedNPV: number;
  WeightedNPVRaw: number;
  Unit: LossSeverityUnit;
};

const getRawValueByUnit = (value: number, unit: LossSeverityUnit): number => {
  switch (unit) {
    case LossSeverityUnit.bln:
      return value * 1_000_000_000;
    case LossSeverityUnit.mln:
      return value * 1_000_000;
    case LossSeverityUnit.k:
      return value * 1_000;
    default:
      return value;
  }
};

const parseLossSeverityPlotPoints = (
  item: string,
  coefficient?: number
): LossSeverityPlotItem[] => {
  let arr: LossSeverityPlotItem[] = [];
  try {
    const obj = JSON.parse(item);
    if (Array.isArray(obj)) {
      arr = [...obj];
    } else if (obj.hasOwnProperty('DataPoints')) {
      arr = [...obj.DataPoints];
    }
  } catch (e) {
    console.error(e);
  }
  return arr.map((el) => {
    if (coefficient) el.LossSeverityAxisX = el.LossSeverityAxisX * coefficient;
    el.LossSeverityAxisXRaw = getRawValueByUnit(el.LossSeverityAxisX, el.LossSeverityAxisXUnit);
    if (el.LossSeverityAxisXRaw === el.LossSeverityAxisX) {
      el.LossSeverityAxisXUnit = LossSeverityUnit.none;
    }
    return el;
  });
};

@Module({ dynamic: true, store, name: 'lossSeverity' })
class LossSeverity extends VuexModule {
  private plotsData: LossSeverityPlotsData = {};
  private plotsDataWithNPV: LossSeverityPlotsData = {};
  private plotsData_Insurable: LossSeverityPlotsData = {};
  private plotsDataWithNPV_Insurable: LossSeverityPlotsData = {};
  private riskGroup: Nullable<LossSeverityRiskGroup> = null;
  private weightedNpv: {
    id: string;
    data: Partial<Record<LossSeverityRiskGroup, WeightedNPVItem>>;
  }[] = [];
  private weightedNpv_Insurable: {
    id: string;
    data: Partial<Record<LossSeverityRiskGroup, WeightedNPVItem>>;
  }[] = [];
  private lossType: LossTypes = LossTypes.economic;
  private lossCoefficient: number = 0.2;

  @Action({ commit: 'setLossSeverityPlots' })
  public fetchLossSeverityPlots() {
    const companyId = store.getters.companyId;
    return fetchDdbQuery(companyId, RANGECOND_LOSS_SEVERITY)
      .then((data) => {
        if (data.length > 0) {
          return data;
        } else {
          throw new Error(RANGECOND_LOSS_SEVERITY + 'request is empty');
        }
      })
      .catch((err) => {
        console.error(err);
        return [];
      });
  }

  @Mutation
  public setLossSeverityPlots(data: LossSeverityQueryResponse[]) {
    if (Array.isArray(data)) {
      const [p1, p2, p3, p4] = data.reduce(
        ([acc1, acc2, acc3, acc4], cur) => {
          this.lossCoefficient = cur.InsurableLossCoefficient
            ? Number(cur.InsurableLossCoefficient)
            : 0.2;
          const plotField = cur.LossSeverityPlot || cur.DataPoints;
          if (cur.RiskGroup && plotField) {
            if (cur.DataType?.includes('#current#')) {
              const items = parseLossSeverityPlotPoints(plotField).map((el) => ({
                ...el,
                ...(cur.LossSeverityAxisXCurrency
                  ? {
                      LossSeverityAxisXCurrency: cur.LossSeverityAxisXCurrency,
                    }
                  : {}),
              }));
              acc1[cur.RiskGroup] = [...items];
              acc2[cur.RiskGroup] = [...items.filter((el) => el.NetPresentValue)];
              const items_Insurable = parseLossSeverityPlotPoints(
                plotField,
                this.lossCoefficient
              ).map((el) => ({
                ...el,
                ...(cur.LossSeverityAxisXCurrency
                  ? {
                      LossSeverityAxisXCurrency: cur.LossSeverityAxisXCurrency,
                    }
                  : {}),
              }));
              acc3[cur.RiskGroup] = [...items_Insurable];
              acc4[cur.RiskGroup] = [...items_Insurable.filter((el) => el.NetPresentValue)];
              if (cur.CurrentRisk === 'TRUE') this.riskGroup = cur.RiskGroup;
            }
          }
          return [acc1, acc2, acc3, acc4];
        },
        [{}, {}, {}, {}] as [
          LossSeverityPlotsData,
          LossSeverityPlotsData,
          LossSeverityPlotsData,
          LossSeverityPlotsData
        ]
      );
      Object.assign(this.plotsData, p1);
      Object.assign(this.plotsDataWithNPV, p2);
      Object.assign(this.plotsData_Insurable, p3);
      Object.assign(this.plotsDataWithNPV_Insurable, p4);
    } else {
      this.riskGroup = null;
      Object.assign(this.plotsData, {});
      Object.assign(this.plotsDataWithNPV, {});
      Object.assign(this.plotsData_Insurable, {});
      Object.assign(this.plotsDataWithNPV_Insurable, {});
    }

    //set risk
    if (BreachProbabilityModule.breachCurrentData && !this.riskGroup) {
      this.riskGroup = null;
      const res = Object.entries(BreachProbabilityModule.breachCurrentData.scheme).find(
        ([_key, value]) => {
          return BreachProbabilityModule.breachCurrentData?.BreachProbability === value;
        }
      );
      if (res) {
        this.riskGroup = riskBreachMap[res[0] as BreachProbabilityRisk];
      }
    }
  }

  @Mutation
  public setLossType(type: LossTypes) {
    this.lossType = type;
  }

  get plots() {
    return this.lossType === LossTypes.economic ? this.plotsData : this.plotsData_Insurable;
  }

  get lossTypeStored() {
    return this.lossType;
  }

  get plotsWithNPV() {
    return this.lossType === LossTypes.economic
      ? this.plotsDataWithNPV
      : this.plotsDataWithNPV_Insurable;
  }

  get plotsWithNPV_Economic() {
    return this.plotsDataWithNPV;
  }

  get currentRiskGroup() {
    return this.riskGroup;
  }

  get currentPlot() {
    return this.plotsWithNPV[LossSeverityModule.currentRiskGroup || 'Low'];
  }

  get plotsWithNPVLength() {
    return this.currentPlot?.length || 0;
  }

  get currency() {
    if (!this.currentPlot) return 'USD';
    return this.currentPlot[0].LossSeverityAxisXCurrency;
  }

  get unit() {
    if (!this.currentPlot) return LossSeverityUnit.mln;
    return this.currentPlot[0].LossSeverityAxisXUnit;
  }

  @Action
  public async fetchWeightedNpv({
    severityFrom,
    severityTo,
    id,
  }: {
    severityFrom: number;
    severityTo: number;
    id: string;
  }) {
    if (this.weightedNpv.find((el) => el.id === id)) return;
    const cognitoToken = await window.CbhRosie.getAuthToken();
    const companyId = store.getters.companyId;
    return axios({
      url: `${severityApiUrl}weightedNpv`,
      method: 'post',
      headers: {
        Authorization: cognitoToken,
      },
      data: {
        companyID: companyId,
        severityFrom,
        severityTo,
      },
    })
      .then(async (response) => {
        if (response.status === 200) {
          store.commit('setWeightedNpv', {
            id,
            data: response.data.weightedNpv,
          });
          return response.data;
        }
        throw new Error();
      })
      .catch(() => {
        console.error(`load weightedNpv data failed`);
        store.commit('setWeightedNpv', { id, data: [] });
      });
  }

  @Mutation
  public setWeightedNpv({ id, data }: { id: string; data: any[] }) {
    const res = data.reduce((pre, cur) => {
      const item: WeightedNPVItem = {
        WeightedNPV: cur.Weighted_NPV,
        WeightedNPVRaw: getRawValueByUnit(cur.Weighted_NPV, cur.Unit),
        Unit: cur.Unit,
      };
      pre[cur.RiskGroup] = item;
      return pre;
    }, {});
    this.weightedNpv.splice(this.weightedNpv.length - 1, 0, { id, data: res });

    const res_Insurable = data.reduce((pre, cur) => {
      const item: WeightedNPVItem = {
        WeightedNPV: cur.Weighted_NPV * this.lossCoefficient,
        WeightedNPVRaw: getRawValueByUnit(cur.Weighted_NPV * this.lossCoefficient, cur.Unit),
        Unit: cur.Unit,
      };
      pre[cur.RiskGroup] = item;
      return pre;
    }, {});

    this.weightedNpv_Insurable.splice(this.weightedNpv_Insurable.length - 1, 0, {
      id,
      data: res_Insurable,
    });
  }

  get weightedNpvMap() {
    return this.lossType === LossTypes.economic ? this.weightedNpv : this.weightedNpv_Insurable;
  }
}

export const LossSeverityModule = getModule(LossSeverity);
