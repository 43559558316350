import { store } from '../../index';
import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import { fetchDdbQuery } from '@/api-rosie/utils';
import { Nullable } from '@/types';

const RANGECOND = 'AttackStage#current';

export interface AttackStageItem {
  StageOver3Months: number;
  StageOver6Months: number;
  StageOver12Months: number;
}

@Module({ dynamic: true, store, name: 'attackStage' })
class AttackStage extends VuexModule {
  private data: Nullable<AttackStageItem> = null;

  @Action({ commit: 'setAttackStageData' })
  public fetchAttackStageData() {
    const companyId = store.getters.companyId;
    return fetchDdbQuery(companyId, RANGECOND)
      .then((data) => {
        if (data.length > 0) {
          return data[0];
        } else {
          throw new Error(RANGECOND + 'request is empty');
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  @Mutation
  public setAttackStageData(data: any) {
    try {
      const item: AttackStageItem = {
        StageOver3Months: Number(data['StageOver3Months']),
        StageOver6Months: Number(data['StageOver6Months']),
        StageOver12Months: Number(data['StageOver12Months']),
      };
      this.data = { ...item };
    } catch (error) {
      console.error('failed to parse AttackStage data');
      this.data = null;
    }
  }

  get stageItem() {
    return this.data;
  }
}

export const AttackStageModule = getModule(AttackStage);
